import { Button } from "@mui/material";
import { useRef, useState } from "react";

interface CloudButtonHandlerProps {
  imageSrcState: [
    string | null,
    React.Dispatch<React.SetStateAction<string | null>>
  ];
  setComputedEggCount: React.Dispatch<React.SetStateAction<number | undefined>>;
  imagesSubmitted: boolean;
  clearImage: () => void;
}

const CloudButtonHandler: React.FC<CloudButtonHandlerProps> = ({
  imageSrcState,
  setComputedEggCount,
  imagesSubmitted,
  clearImage,
}) => {
  const [imageSrc, setImageSrc] = imageSrcState;
  const inputImageRef = useRef<HTMLInputElement | null>(null);
  const [streaming, setStreaming] = useState<string | null>(null);
  const [dragging, setDragging] = useState<boolean>(false); // Track drag state

  const handleClear = () => {
    clearImage();
    setStreaming(null);
    if (inputImageRef.current) {
      inputImageRef.current.value = "";
    }
  };

  const handleFile = (file: File) => {
    const url = URL.createObjectURL(file);
    setImageSrc(url);
    setStreaming("image");
  };

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    const file = e.dataTransfer.files?.[0];
    if (file) {
      handleFile(file);
    }
  };

  return (
    <div
      className={`btn-container ${dragging ? "dragging" : ""}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={{
        border: dragging ? "2px dashed #aaa" : "2px solid transparent",
        padding: "20px",
        borderRadius: "10px",
      }}
    >
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (!file) return;
          handleFile(file);
        }}
        ref={inputImageRef}
      />

      {imagesSubmitted ? null : (
        <Button
          variant="outlined"
          color={streaming === "image" ? "error" : "primary"}
          onClick={() => {
            if (!streaming) {
              inputImageRef.current?.click();
            } else if (streaming === "image") {
              handleClear();
            } else {
              alert(
                `Can't handle more than 1 stream\nCurrently streaming: ${streaming}`
              );
            }
          }}
        >
          {streaming === "image" ? "Clear" : "Select"} Image
        </Button>
      )}

      {/* Drag and drop hint */}
      {!streaming && !dragging && (
        <p style={{ textAlign: "center", color: "#aaa", marginTop: "10px" }}>
          Drag and drop an image or click to select
        </p>
      )}
    </div>
  );
};

export default CloudButtonHandler;
