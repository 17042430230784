import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { Link } from "@mui/material";

export default function SponsorImages() {
  return (
    <ImageList style={{ padding: 10 }} cols={4}>
      {itemData.map((item) => (
        <a href={item.url} key={item.img}>
          <ImageListItem key={item.img}>
            {/* <a href={item.url}> */}

            <img
              src={`${item.img}`}
              srcSet={`${item.img}`}
              alt={item.title}
              loading="lazy"
              style={{ objectFit: "contain", maxHeight: 100 }}
            />
            {/* </a> */}
            {/* <ImageListItemBar
            title={item.title}
            // subtitle={<span>by: {item.author}</span>}
            position="below"
          /> */}
          </ImageListItem>
        </a>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: "/asph_logo.png",
    title: "University of South Carolina",
    url: "https://sc.edu/study/colleges_schools/public_health/index.php",
    // author: '@bkristastucchio',
  },
  {
    img: "./thumbnail_solid-blue-baylor-logo.png",
    title: "Baylor University",
    url: "https://www.bcm.edu/",
    // author: '@nolanissac',
  },
  {
    img: "./fiu-white-logo.png",
    title: "Florida International University",
    url: "https://www.fiu.edu/",
    // author: '@rollelflex_graphy726',
  },
  {
    img: "./nih-logo-color.png",
    title: "National Institute of Health",
    url: "https://www.nih.gov/",
    // author: '@helloimnik',
  },
];
